import BaseTopNav from '@/components/base/baseTopNav';
import { useTranslation } from 'react-i18next';
import WithdrawSuccessfullyTips from '@/pages/funds/withdrawSuccessfully/components/WithdrawSuccessfullyTips';

/**
 * 提现审核中页面
 * */
export default () => {
  const { t } = useTranslation();

  return (
    <>
      <BaseTopNav title={t('提现申请成功')} />
      <WithdrawSuccessfullyTips />
    </>
  );
};