import { LineMdCoffeeLoop } from '@/assets/icons/mine/LineMdCoffeeLoop';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { history, useModel } from '@@/exports';
import { CompletedOrderOrderStatusEnum, CustomerServiceTypeEnum } from '@/enums/businessEnum';
import { useReq } from '@/services/net/request';
import { cancelWithdrawApi } from '@/services/api/requestApi';
import { toast } from '@/layouts/components/ToastMessage';
import useUrlState from '@ahooksjs/use-url-state';

/**
 * 默认导出一个函数组件，用于展示充值确认页面。
 * 该组件不接受任何参数，并且不直接返回数据，而是渲染了一个包含充值状态提示和客服按钮的页面。
 */
export default () => {
  // 使用useTranslation钩子来获取国际化工具方法
  const { t } = useTranslation();
  const { gotoCustomerService } = useModel('imSocket');
  const { refreshWallet } = useModel('user');
  const [urlState] = useUrlState() || {};
  const { id } = urlState as any;
  const { run } = useReq(cancelWithdrawApi,{
    manual:true,
    onSuccess(){
      toast.success(t("已取消"))
      refreshWallet()
      history.back()
    }
  });

  return (
    <div className="h-[80vh] sm:h-[70vh] relative">
      {/* 充值确认页面的顶部导航 */}
      <div className="px-4 mt-16">
        <div className="flex flex-col items-center justify-center  h-[200px]">
          <LineMdCoffeeLoop
            width="50px"
            height="50px"
            className="text-primary"
          />{' '}
          {/* 充值图标 */}
          <div className="text-xl text-backContrastColor my-4">
            {t('审核中')}
          </div>
          <div className="text-auxiliaryTextColor px-4">
            {t('提现信息已提交，工作人员将尽快审核，请您耐心等待。')}{' '}
          </div>
        </div>

        <div className="flex flex-col items-center absolute  bottom-4 bg-background left-0 px-4 w-full">
          <Button
            onClick={() => {
              run({id: id})
            }}
            className="px-10 mainColorButton !bg-backgroundAuxiliaryColor w-full   !text-foreground "
          >
            {t('取消提现')}
          </Button>
          <Button
            className="mainColorButton   mt-4 mx-2"
            onClick={() => {
              gotoCustomerService();
            }}
          >
            {' '}
            {t('联系客服')}
          </Button>{' '}
        </div>
      </div>
    </div>
  );
};
